<template>
  <div class="newstab-container">
    <!-- <div class="news-link-icon">
      <router-link to="/News"></router-link>
    </div> -->
    <div class="newstab-title">
      <div
        v-for="(item, key) in titleList"
        :key="key"
        @click="changeTab(key)"
        class="title"
        :class="{ activeTitle: activeKey === key }"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="newstab-content-wrap">
      <div
        v-for="(item, key) in contentList"
        :key="key"
        class="newstab-content"
        :class="{ activeContent: activeKey === key }"
      >
        <div v-for="(content, contentKey) in item.data.list" :key="contentKey">
          <a
            :href="content.jump_url"
            v-if="content.jump_url"
             target="_blank"
            class="newstab-content-detail"
          >
            <div v-if="isMobile">
              <div class="content">
                <div class="content-title">{{ content.title }}</div>
                <div class="content-detail">{{ content.intro }}</div>
              </div>
              <div class="content-time">
                {{ timeFormat(content.tm_publish_s) }}
              </div>
            </div>
            <div v-else>
              <div class="content-title">{{ content.title }}</div>
              <div class="content">
                <div class="content-detail">{{ content.intro }}</div>
                <div class="content-time">
                  {{ timeFormat(content.tm_publish_s) }}
                </div>
              </div>
            </div>
          </a>
          <router-link
            v-else
            :to="'/NewsDetail/' + content.id"
            class="newstab-content-detail"
            target="_blank"
          >
            <div v-if="isMobile">
              <div class="content">
                <div class="content-title">{{ content.title }}</div>
                <div class="content-detail">{{ content.intro }}</div>
              </div>
              <div class="content-time">
                {{ timeFormat(content.tm_publish_s) }}
              </div>
            </div>
            <div v-else>
              <div class="content-title">{{ content.title }}</div>
              <div class="content">
                <div class="content-detail">{{ content.intro }}</div>
                <div class="content-time">
                  {{ timeFormat(content.tm_publish_s) }}
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <router-link :to="'/News?activeKey=' + activeKey" class="news-more"  target="_blank">
      <img src="../../../assets/img/news-more.png" alt="" />
    </router-link>
  </div>
</template>

<script>
import { getChildList, getNewest } from "@/api/index";
import { timeFormat, isMobile } from "@/utils/util";

export default {
  name: "NewsTab",
  data() {
    return {
      titleList: [],
      isMobile,
      contentList: [],
      activeKey: 0,
      list1: [],
      list2: [],
      list3: [],
    };
  },
  methods: {
    changeTab(key) {
      this.activeKey = key;
    },
    timeFormat(time) {
      return timeFormat(time * 1000);
    },
  },
  mounted() {
    getChildList({ mid: 1 }).then(({ data }) => {
      data.unshift({ mid: "1", name: "NEW" });
      this.titleList = data;
      Promise.all([
        getNewest({ mid: 1, num: 5 }),
        getNewest({ mid: 18, num: 4 }),
        getNewest({ mid: 3, num: 4 }),
        getNewest({ mid: 2, num: 4 }),
      ])
        .then((result) => {
          console.log(result);
          this.contentList = result;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
};
</script>

<style lang="scss" scoped>
.newstab-container {
  margin-left: 10px;
  position: relative;
}

.newstab-content {
  display: none;
}

.activeContent {
  display: block;
}
</style>


<style lang="scss" scoped>
// @media (max-width: 375px),only screen and (min-device-pixel-ratio: 2) {
@media (max-width: 750px) {
  .newstab-container {
    position: relative;
    margin-left: 0;
  }
  .news-link-icon {
    position: absolute;
    top: 0.36rem;
    right: 0.23rem;
    width: 0.24rem;
    height: 0.24rem;
    background-image: url(../../../assets/img/goNewsIcon.png);
    background-size: cover;
    a {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .newstab-title {
    display: flex;
    background-image: url(../../../assets/img/app/newsTabBg.png);
    background-size: cover;
    width: 95vw;
    
    .title {
      height: 0.9rem;
      width: 25%;
      line-height: 0.9rem;
      text-align: center;
      color: #15385b;
      cursor: pointer;
      font-size: 0.28rem;
      // background-image: url(../../../assets/img/tabTitleBg.png);
    }
    .activeTitle {
      background: url(../../../assets/img/app/newsCheckBorder.png);
      background-size: cover;
      // width: 248px;
      position: relative;
    }
    .activeTitle::before {
      content: "";
      display: block;
      position: absolute;
      top: 0.05rem;
      left: 0.06rem;
      width: 0.35rem;
      height: 0.4rem;
      background: url(../../../assets/img/app/newsCheckIcon.png) no-repeat;
      background-size: contain;
    }
  }
  .newstab-content-wrap {
    position: relative;
    width: 100%;
    height: 9.2rem;
    .newstab-content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-image: url(../../../assets/img/tabContentBg.png);
      // background-color: #fff;
      padding: 8px 30px 0 30px;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .newstab-content-detail {
    display: block;
    border-bottom: 1px dashed #15385b;
    .content {
      color: #5684b3;
      font-size: 0.2rem;
      height: 1.1rem;
      line-height: 1.5;
      padding-top: .1rem;
    }
    .content-title {
      font-size: 0.26rem;
      color: #15385b;
      max-height: 0.83rem;
      overflow: hidden;
    }

    .content-detail {
      // width: 75%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
    .content-time {
      font-size: 0.2rem;
      line-height: 1.5;
      text-align: right;
    }
  }
  .newstab-content-detail:hover .content,
  .newstab-content-detail:hover .content-title {
    color: #b79350;
  }

  .news-more {
    position: absolute;
    bottom: 0.2rem;
    right: 0.3rem;
    // width: 1rem;
    height: 0.4rem;
    z-index: 19;
    img {
      // width: 100%;
      height: 100%;
      max-width: 100%;
    }
  }
}
@media (min-width: 751px) {
  .news-link-icon {
    position: absolute;
    top: 20px;
    right: 42px;
    width: 42px;
    height: 42px;
    background-image: url(../../../assets/img/goNewsIcon.png);
    background-size: cover;
    a {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .newstab-title {
    display: flex;
    // justify-content: center;
    padding-left: 80px;
    background-image: url(../../../assets/img/titleWrap.png);
    background-size: cover;
    // width: 95vw;
    .title {
      height: 84px;
      width: 200px;
      line-height: 84px;
      text-align: center;
      color: #15385b;
      cursor: pointer;
      font-size: 28px;
      // background-image: url(../../../assets/img/tabTitleBg.png);
    }
    .activeTitle {
      background: url(../../../assets/img/newsCheckBoeder.png);
      background-size: cover;
      width: 248px;
    }
  }

  .newstab-content-wrap {
    position: relative;
    width: 100%;
    height: 462px;
    .newstab-content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-image: url(../../../assets/img/tabContentBg.png);
      // background-color: #fff;
      padding: 8px 30px 0 30px;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .newstab-content-detail {
    display: block;
    padding: 8px 0 13px;
    padding-right: 32px;
    border-bottom: 1px dashed #15385b;
    .content-title {
      width: 87%;
      margin-bottom: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      font-size: 24px;
      color: #15385b;
      line-height: 1.2;
    }
    .content {
      display: flex;
      justify-content: space-between;
      color: #5684b3;
      font-size: 16px;
    }
    .content-detail {
      width: 75%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      line-height: 1.2;
    }
    .content-time {
      text-align: right;
    }
  }
  .newstab-content-detail:hover .content,
  .newstab-content-detail:hover .content-title {
    color: #b79350;
  }

  .news-more {
    position: absolute;
    bottom: 10px;
    right: 46px;
    z-index: 19;
    img {
      width: 100px;
    }
  }
}
</style>

