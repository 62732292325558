<template>
  <div v-if="isMobile" class="audioVisual-container">
    <div class="audioVisual-title">
      <div
        v-for="(item, key) in titleList"
        :key="key"
        @click="changeTab(key)"
        class="title"
        :class="{ activeTitle: activeKey === key }"
      >
        {{ item }}
      </div>
    </div>
    <div class="audioVisual-wrap">
      <div class="audioVisual-content" v-show="activeKey === 0">
        <div
          class="video-wrap"
          v-for="(video, videoKey) in secondTitleList"
          :key="videoKey"
        >
          <div class="video-class">
            <div class="video-name">
              <div class="video-name-child">
                {{ secondTitleTextList[videoKey] }}
              </div>
              <router-link
                class="more"
                :to="'/AudioVisual?videoKey=' + video.mid"
                target="_blank"
              ></router-link>
            </div>
          </div>
          <div class="video-list scrollStyle">
            <div
              class="video"
              v-for="(videoItem, key) in videoList[videoKey]"
              :key="key"
              @click="showPlayWrap(videoItem.youtube_id)"
            >
              <div class="audio-picture">
                <img :src="videoItem.img" alt="" />
              </div>
              <div class="video-name">
                {{ videoItem.title || videoItem.intro }}
              </div>
              <div class="audio-play">
                <div class="player"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="image-content" v-show="activeKey === 1">
        <div
          v-for="(item, key) in imgList"
          :key="key"
          class="image-wrap"
          @click="showImgWrap(item.img)"
        >
          <div class="image">
            <img :src="item.img" alt="" />
          </div>
          <div class="image-name">{{ item.title }}</div>
        </div>
        <viewer :images="images">
          <img v-for="src in images" :key="src" :src="src" />
        </viewer>
      </div>
    </div>
    <!-- <div class="audioArrow-wrap" v-show="activeKey === 0"> -->
    <div class="arrow-left" v-show="activeKey === 0"></div>
    <div class="arrow-right" v-show="activeKey === 0"></div>
    <!-- </div> -->
    <router-link class="audioVisual-more" to="/AudioVisual" target="_blank">
      <img src="../../../assets/img/news-more.png" alt="" />
    </router-link>
    <div
      class="video-container"
      v-show="isShowPlayWrap"
      @click.stop="closePlayWrap()"
    >
      <iframe
        :src="src"
        frameborder="0"
        allowfullscreen="allowfullscreen"
      ></iframe>
    </div>
  </div>
  <div v-else class="audioVisual-container">
    <div class="audioVisual-title">
      <div
        v-for="(item, key) in titleList"
        :key="key"
        @click="changeTab(key)"
        class="title"
        :class="{ activeTitle: activeKey === key }"
      >
        {{ item }}
      </div>
    </div>
    <div class="audioVisual-wrap">
      <div class="audioVisual-content" v-show="activeKey === 0">
        <div
          class="video-wrap"
          v-for="(video, videoKey) in secondTitleList"
          :key="videoKey"
        >
          <div class="video-class">
            <div class="video-name">
              <div class="video-name-child">
                {{ secondTitleTextList[videoKey] }}
              </div>
            </div>
          </div>
          <div class="video-list scrollStyle">
            <div
              class="video"
              v-for="(videoItem, key) in videoList[videoKey]"
              :key="key"
              @click="showPlayWrap(videoItem.youtube_id)"
            >
              <div class="audio-picture">
                <img :src="videoItem.img" alt="" />
              </div>
              <div class="audio-name">
                {{ videoItem.title || videoItem.intro }}
              </div>
              <div class="audio-play">
                <div class="player"></div>
              </div>
            </div>
          </div>
        </div>
        <router-link class="audioVisual-more" to="/AudioVisual" target="_blank">
          <img src="../../../assets/img/news-more.png" alt="" />
        </router-link>
      </div>
      <div class="audioVisual-content" v-show="activeKey === 1">
        <div class="audioVisual-content" v-show="activeKey === 1">
          <div class="second-content scrollStyle">
            <div v-for="(item, key) in imgList" :key="key" class="audio-wrap">
              <div class="image" @click="showImage(key)">
                <img :src="item.img" alt="" />
              </div>
              <div class="audio-name">{{ item.title || item.intro }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="video-container"
      v-show="isShowPlayWrap"
      @click.stop="closePlayWrap()"
    >
      <!-- <div class="video-wrap">
        <div class="close" @click="closePlayWrap()"></div>
        <div class="video-content">
          <iframe
            :src="src"
            frameborder="0"
            allowfullscreen="allowfullscreen"
            width="100%"
            height="85%"
          ></iframe>
        </div>
      </div> -->
      <div class="video-wrap">
        <div class="border-top"></div>
        <div class="border-right"></div>
        <div class="border-bottom"></div>
        <div class="border-left"></div>
        <div class="video-content">
          <iframe
            :src="src"
            frameborder="0"
            allowfullscreen="allowfullscreen"
            width="100%"
            height="85%"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getChildList, getVideoList, getImgList } from "@/api/index";
import { isMobile } from "@/utils/util";
import { ImagePreview } from "vant";
// ,getVideoList,getArticle,VudilPlayer
export default {
  name: "AudioVisual",
  data() {
    return {
      src: "",
      titleList: ["ムービー", "グラフィック"],
      contentList: [0, 1],
      videoList: [],
      secondTitleList: [],
      activeKey: 0,
      videoList1: [],
      videoList2: [],
      videoList3: [],
      isShowPlayWrap: false,
      isMobile,
      secondTitleTextList: [
        "公式宣伝ム|ビ|",
        "公式ゲ|ムム|ビ|",
        "プレイヤ|ム|ビ|",
      ],
      secondImgTitleList: [
        "グラフィック",
        "ハイクオリティ壁紙",
        "一般創作画像",
      ],
      imgList: [],
      imgList0: [],
      imgList1: [],
      imgList2: [],
    };
  },
  methods: {
    changeTab(key) {
      this.activeKey = key;
    },
    showPlayWrap(id) {
      // console.log(id);
      this.src =
        "https://www.youtube.com/embed/" +
        id +
        "?autoplay=1&controls=1&disablekb=1&&rel=0&loop=1&enablejsapi=1&mute=1&arel=0";
      this.isShowPlayWrap = true;
    },
    closePlayWrap() {
      this.isShowPlayWrap = false;
      this.src = "";
    },
    showImage(key) {
      console.log(key);
      if (!this.previewList) {
        this.previewList = this.imgList.map((item) => {
          return item.img;
        });
      }
      this.$viewerApi({
        images: this.previewList,
        options: {
          initialViewIndex: key,
        },
      });
    },
    showImgWrap(id) {
      ImagePreview({ images: [id], showIndex: false });
    },
  },
  mounted() {
    getChildList({ mid: 5 }).then((res) => {
      console.log(1, res);
      this.secondTitleList = res.data;

      Promise.all([
        getVideoList({ mid: 8, num: 3 }),
        getVideoList({ mid: 7, num: 3 }),
        getVideoList({ mid: 6, num: 3 }),
      ]).then((result) => {
        // console.log(2, result);

        this.videoList = [
          result[0].data.list,
          result[1].data.list,
          result[2].data.list,
        ];
      });
    });
    Promise.all([
      getImgList({ mid: 9, num: 3 }),
      getImgList({ mid: 10, num: 3 }),
      getImgList({ mid: 11, num: 3 }),
    ]).then((result) => {
      // console.log(2, result);
      this.imgList = [
        ...result[0].data.list,
        ...result[1].data.list,
        ...result[2].data.list,
      ];
    });
  },
};
</script>



<style lang="scss" scoped>

@media (min-width: 751px) {
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .audioVisual-title {
    display: flex;
    // justify-content: center;
    background-image: url(../../../assets/img/titleWrap.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    padding-left: 78px;
    margin-bottom: 58px;
    .title {
      height: 84px;
      width: 248px;
      line-height: 84px;
      text-align: center;
      color: #15385b;
      cursor: pointer;
      font-size: 28px;
    }
    .activeTitle {
      background: url(../../../assets/img/newsCheckBoeder.png);
      background-size: cover;
      // width: 248px;
      padding-left: 65px;
      width: 258px;
      padding-right: 10px;
      box-sizing: border-box;
    }
  }
  .audioVisual-wrap {
    position: relative;
    margin: 0 58px;
    height: 700px;
    .audioVisual-content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      // background-color: #fff;
      display: flex;

      .audioVisual-more {
        position: absolute;
        bottom: -27px;
        right: 30px;
        img {
          width: 100px;
        }
      }
    }
    .activeContent {
      // z-index: 9;
    }
  }

  .video-wrap {
    display: flex;
    .video-class {
      height: 464px;
      width: 210px;
      margin-left: 78px;
      background-image: url(../../../assets/img/videoClass.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      position: relative;
      .video-name {
        width: 20px;
        font-size: 18px;
        color: #15385b;
        .more {
          position: absolute;
          bottom: 108px;
          left: 52px;
          width: 102px;
          height: 38px;
          // border: 1px solid #000;
        }
      }
    }

    .video {
      // width: 275px;
      position: relative;
      margin-bottom: 28px;
      // height: 171px;
      .video-content {
        img {
          width: 100%;
        }
      }
      .video-name {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        line-height: 36px;
        color: #15385b;
        background: #afdbec;
      }
    }
  }
  .video-list {
    height: 602px;
    width: 275px;
    overflow: auto;
  }
  .video-list::-webkit-scrollbar {
    width: 11px;
    height: 10px;
  }

  .video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.2);
    z-index: 9;
    .video-wrap {
      width: 1070px;
      height: 600px;
      position: absolute;
      top: 0;
      bottom: -50px;
      left: 0;
      right: 0;
      margin: auto;
      .close {
        position: absolute;
        top: 16px;
        right: 54px;
        width: 51px;
        height: 124px;
        background: url(../../../assets/img/audio/close.png) no-repeat;
        background-size: cover;
      }
      .border-top,
      .border-right,
      .border-bottom,
      .border-left {
        position: absolute;
        z-index: 6;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .border-top {
        width: 126%;
        height: 60px;
        top: -21px;
        left: -129px;
        background-image: url(../../../assets/img/audio/border-top.png);
      }
      .border-right {
        top: 0;
        right: -147px;
        width: 163px;
        height: 100%;
        background-image: url(../../../assets/img/audio/border-right.png);
      }
      .border-bottom {
        bottom: -78px;
        left: -129px;
        width: 126%;
        height: 85px;
        background-image: url(../../../assets/img/audio/border-bottom.png);
      }
      .border-left {
        left: -127px;
        top: 0;
        width: 141px;
        height: 100%;
        background-image: url(../../../assets/img/audio/border-left.png);
      }
    }
  }
  .audio-picture {
    cursor: pointer;
    height: 135px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .image {
    height: 135px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .video-content {
    width: 100%;
    margin: 0 auto;
    // padding: 20px;
    height: 100%;
    position: relative;
    // left: -8px;
    video {
      width: 100%;
      height: 100%;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .audio-name {
    line-height: 36px;
    text-align: center;
    background: #afdbec;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }

  .img-content {
    display: flex;
    justify-content: space-between;
    width: 70vw;
    margin: 0 auto;
    .img-list {
      height: 600px;
      overflow-y: auto;
      overflow-x: hidden;
      width: 275px;
      .img-wrap {
        .img-name {
          width: 275px;
          line-height: 36px;
          text-align: center;
          background: #afdbec;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        img {
          min-height: 135px;
          max-width: 275px;
        }
      }
    }
  }

  .second-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 1430px;
    margin: 0 auto;
    padding-top: 50px;
    // padding-right: 70px;
    padding-left: 50px;
    .audio-wrap {
      width: 300px;
      margin-bottom: 26px;
      margin-right: 50px;
    }
  }

  .video-name-child {
  text-align: center;
  overflow-wrap: break-word;
  padding-bottom: 30px;
}

  .audio-play {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    .player {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 92px;
      width: 92px;
      // background: #fff;
    }
    .player:before,
    .player::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
    .player::before {
      width: 30px;
      height: 32px;
      background: url(../../../assets/img/audio/sanjiao.png) no-repeat 50%/100%
        100%;
    }
    .player::after {
      width: 100%;
      height: 100%;
      background: url(../../../assets/img/audio/sanjiaoBG.png) no-repeat 50%/100%
        100%;
      animation: rotate 15s linear infinite;
    }
  }
}

@media (max-width: 750px) {
  .audioVisual-container {
    position: relative;
  }
  .audioVisual-title {
    display: flex;
    background-image: url(../../../assets/img/app/newsTabBg.png);
    background-size: cover;
    width: 93vw;
    margin: 0 auto 0.56rem;
    .title {
      height: 0.9rem;
      width: 2.2rem;
      line-height: 0.9rem;
      text-align: center;
      color: #15385b;
      cursor: pointer;
      font-size: 0.28rem;
      // background-image: url(../../../assets/img/tabTitleBg.png);
    }
    .activeTitle {
      background: url(../../../assets/img/app/newsCheckBorder.png);
      background-size: cover;
      // width: 248px;
      position: relative;
    }
    .activeTitle::before {
      content: "";
      display: block;
      position: absolute;
      top: 0.05rem;
      left: 0.06rem;
      width: 0.35rem;
      height: 0.4rem;
      background: url(../../../assets/img/app/newsCheckIcon.png) no-repeat;
      background-size: contain;
    }
  }
  .audioVisual-wrap {
    position: relative;
    // width: 93vw;
    // margin: 0 auto;
    height: 9.6rem;
    overflow: auto;
    .audioVisual-content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      // background-color: #fff;
      display: flex;
    }
    .activeContent {
      // z-index: 9;
    }
  }
  .audioVisual-more {
    position: absolute;
    bottom: 0;
    right: 0.3rem;
    height: 0.4rem;
    img {
      height: 100%;
      max-width: 100%;
    }
  }

  .video-wrap {
    display: flex;
    width: 90vw;
    .video-class {
      height: 6rem;
      width: 1.5rem;
      background-image: url(../../../assets/img/videoClass.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      position: relative;
      .video-name {
        width: 18%;
        font-size: 0.26rem;
        color: #15385b;
        line-height: 1;
        padding-bottom: 0.4rem;
        .more {
          position: absolute;
          bottom: 1.2rem;
          left: 0.2rem;
          width: 0.9rem;
          height: 0.4rem;
          // border: 1px solid #000;
        }
      }
    }

    .video {
      // width: 275px;
      position: relative;
      margin-bottom: 0.2rem;
      // height: 171px;
      .video-content {
        img {
          width: 100%;
        }
      }
      .video-name {
        // position: absolute;
        // bottom: 0;
        width: 100%;
        text-align: center;
        line-height: 1.5;
        font-size: 0.3rem;
        color: #15385b;
        background: #afdbec;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }

  .audio-picture {
    img {
      width: 100%;
      height: 2.4rem;
    }
  }
  .audio-play {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    .player {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 1.8rem;
      width: 1.8rem;
      // background: #fff;
    }
    .player:before,
    .player::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
    .player::before {
      width: 0.4rem;
      height: 0.5rem;
      background: url(../../../assets/img/audio/sanjiao.png) no-repeat 50%/100%
        100%;
    }
    .player::after {
      width: 100%;
      height: 100%;
      background: url(../../../assets/img/audio/sanjiaoBG.png) no-repeat 50%/100%
        100%;
      animation: rotate 15s linear infinite;
    }
  }

  .video-list {
    height: 9.6rem;
    width: 60vw;
    overflow: auto;
    // border: 1px solid #000;
  }
  .video-list::-webkit-scrollbar {
    width: 11px;
    height: 10px;
  }

  .video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // background: rgba($color: #000000, $alpha: 0.2);
    z-index: 31;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .iframe {
      width: 90%;
      height: auto;
    }
  }

  .video-content {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    height: 100%;
    position: relative;
    left: -8px;
    video {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .iframe {
      width: 100%;
      height: 85%;
    }
  }
  .image {
    img {
      width: 100%;
      height: 2.4rem;
    }
  }

  .image-content {
    width: 93vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 60vh;
    margin: 0 auto;
    padding-top: 0.2rem;
    padding-bottom: 2rem;
    overflow: auto;
    .image-wrap {
      width: 49%;
      margin-bottom: 0.1rem;
      img {
        width: 100%;
        height: 2.4rem;
      }
      .image-name {
        font-size: 0.3rem;
        line-height: 1.5;
        text-align: center;
        background: #afdbec;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }

  .video-name-child {
  text-align: center;
  overflow-wrap: break-word;
}

  .arrow-left,
  .arrow-right {
    width: 0.5rem;
    height: 0.5rem;
    // background: #000;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 6.5rem;
    z-index: 3;
  }
  .arrow-left {
    left: 0;
    background-image: url(../../../assets/img/audio/arrow-l.png);
  }
  .arrow-right {
    right: 0;
    background-image: url(../../../assets/img/audio/arrow-r.png);
  }
}
</style>