<template>
  <div class="container">
    <div class="appBanner-wrap" v-if="isMobile">
      <img class="appBanner" src="../../assets/img/app/Appbanner.jpg" alt="" />
      <!-- <div class="slogen-wrap">
        <img src="../../assets/img/app/slogen.png" alt="">
      </div> -->
      <div class="bottomArrow">
        <img class="downAnimation" src="../../assets/img/app/bottomArrow.png" alt="" />
      </div>
    </div>

    <div v-if="isMobile" class="banner">
      <div class="gamePlay">
        <img
          class="newsTitleimg"
          src="../../assets/img/app/home/newsTitleimg.png"
          alt=""
        />
      </div>
      <div class="swiper" v-if="bannerSwiperList.length > 0">
        <swiper ref="mySwiper" :options="newsSwiperOptions">
          <swiper-slide v-for="(item, key) in bannerSwiperList" :key="key">
            <div class="swiper-item">
              <a :href="item.jump_url" v-if="item.jump_url" target="_blank">
                <img :src="item.img" alt="" />
              </a>
              <img v-else :src="item.img" alt="" />
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <div v-else class="banner">
      <img src="../../assets/img/KV1.jpg" alt="" />
    </div>
    <div class="news">
      <div class="swiper" v-if="!isMobile && bannerSwiperList.length > 0">
        <swiper ref="mySwiper" :options="newsSwiperOptions">
          <swiper-slide v-for="(item, key) in bannerSwiperList" :key="key">
            <div class="swiper-item">
              <a :href="item.jump_url" v-if="item.jump_url" target="_blank">
                <img :src="item.img" alt="" />
              </a>
              <img v-else :src="item.img" alt="" />
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
      <div class="newsTab">
        <NewsTab />
      </div>
    </div>
    <Introduction />
    <Features />
    <AudioVisual />
    <PageData />
    <Footer />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

// import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from "vue-awesome-swiper";

import NewsTab from "./components/NewsTab.vue";
import Introduction from "./components/Introduction.vue";
import Features from "./components/Features.vue";
import AudioVisual from "./components/AudioVisual.vue";
import PageData from "./components/PageData.vue";
import Footer from '@/components/Footer.vue';

import { getChildList, getSwiperList } from "@/api/index";

export default {
  name: "Home",
  components: {
    NewsTab,
    Introduction,
    Features,
    AudioVisual,
    PageData,
    swiper,
    swiperSlide,
    Footer
  },
  data() {
    return {
      headerText: "默认",
      newsSwiperOptions: {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        preventClicks: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      bannerSwiperList: [],
    };
  },
  computed: mapState(["isMobile"]),
  methods: {
    ...mapMutations(["increment","setMusic"]),
    async getChildDetail() {
      const newsChild = await getChildList({ mid: 1 });
      console.log(newsChild);
    },
    handleScroll() {
      const scroll =
        document.documentElement.scrollTop || document.body.scrollTop;

      const heightList = [800, 1800, 3000, 4200];
      let actuveKey = 0;
      heightList.map((item, key) => {
        if (scroll >= item && scroll < heightList[key + 1]) {
          actuveKey = key + 1;
        }
        if (scroll >= 4200) {
          actuveKey = 4;
        }
      });
      this.increment({ status: actuveKey });
    },
  },
  mounted() {
    this.increment({ status: 0 });
    this.setMusic({ status: true });
    window, scrollTo(0, 0);
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
    const device_type = this.isMobile?'mob':'web';
    getSwiperList({ mid: 22, device_type }).then((res) => {
      // console.log("getSwiperList", res);
      this.bannerSwiperList = res.data;
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, false);
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 751px) {
  .container {
    background: url(../../assets/img/新闻公告.png);
    background-size: cover;
    // padding-top: 87px;
    // padding-top: 20px;
  }
  .swiper-container {
    height: 100%;
  }
  .swiper-item {
    color: #fff;
    text-align: center;
    height: 546px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    top: 50%;
  }

  .banner {
    // margin-top: 34px;
    // padding-top: 26px;
    // height: 880px;
    // margin: 0 58px;
    margin-bottom: 25px;
    img {
      width: 100%;
      margin: 0 auto;
    }
  }

  .news {
    display: flex;
    // height: 300px;
    // background: red;
    margin: 0 58px;
    .swiper {
      width: 732px;
      position: relative;
    }
    .newsTab {
      flex: 1;
    }
  }
  .swiper:hover .swiper-button-prev,
  .swiper:hover .swiper-button-next {
    display: block;
  }

  .features {
    height: 300px;
    // background: #0f0;
  }
  .audioVisual {
    height: 300px;
  }
  .pageData {
    height: 300px;
    // background: #ff0;
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 750px) {
  .container {
    background: url(../../assets/img/新闻公告.png);
    background-size: cover;
    // padding-top: 1.32rem;
  }
  .appBanner-wrap {
    position: relative;
    .appBanner {
      width: 100vw;
      height: auto;
    }
    .bottomArrow {
      position: absolute;
      bottom: 0;
      width: 100vw;
      height: 5rem;
      img {
        display: block;
        margin: 0 auto;
      }
      .downAnimation {
        animation: downward 4s linear infinite;
      }
      @keyframes downward {
        0% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-0.8rem);
        }
        100% {
          transform: translateY(0rem);
        }
      }
    }
  }
  .slogen-wrap {
    position: absolute;
    bottom: 7rem;
    left: 0;
    img {
      width: 50%;
      margin: 0 auto;
    }
  }

  .gamePlay {
    // position: absolute;
    // top: 0.3rem;
    // left: 45%;
    width: 1.5rem;
    height: 1.5rem;
    margin: 1rem auto 0.5rem;
    img {
      width: 100%;
    }
  }
  .banner {
    // margin: 0 58px;
    width: 95vw;
    margin: 0 auto 0.3rem;
    // margin-bottom: 60px;
    .swiper {
      .swiper-item {
        height: 3.5rem;
        img {
          width: 100%;
          height: 100%;
          // max-height: 100%;
        }
      }
    }
  }
  .news {
    width: 95vw;
    margin: 0.5rem auto 0;
  }
}
</style>
