<template>
  <div class="pageData-container">
    <div class="pageData-icon"></div>
    <div class="mobile-wrap" v-if="isMobile">
      <div class="pageData-title">
        <div
          v-for="(item, key) in titleList"
          :key="key"
          @click="changeTab(key)"
          class="title"
          :class="{ activeTitle: activeKey === key }"
        >
          {{ item }}
        </div>
      </div>
      <div class="pageData-wrap">
        <div class="pageData-content" v-show="activeKey === 0">
          <swiper ref="mySwiper" :options="swiperOptions"  v-if="bookList.length">
            <swiper-slide v-for="(book, key) in bookList" :key="key">
              <router-link :to="'/PageData?mid=' + book.mid" class="book" target="_blank">
                <span>{{ book.name }}</span>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div class="mobile-wrap" v-else>
      <div class="pageData-title">
        <div
          v-for="(item, key) in titleList"
          :key="key"
          @click="changeTab(key)"
          class="title"
          :class="{ activeTitle: activeKey === key }"
        >
          {{ item }}
        </div>
      </div>
      <div class="pageData-wrap">
        <div class="pageData-content" v-show="activeKey === 0">
          <router-link
            v-for="(book, key) in bookList"
            :to="'/PageData?mid=' + book.mid"
            :key="key"
             target="_blank"
          >
            <span>{{ book.name }}</span>
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getChildList } from "@/api/index";
import { isMobile } from "@/utils/util";

export default {
  name: "PageData",
  data() {
    return {
      titleList: ["ゲーム資料"],
      // titleList: ["ゲーム資料", "山海地図"],
      // titleList: ["ゲーム資料", "山海資料", "山海地図"],
      contentList: [0, 1],
      bookList: [],
      activeKey: 0,
      isMobile,
      swiperOptions: {
        loop: true,
        // loopAdditionalSlides: 2,
        slidesPerView: 3,
        // slidesPerView: 'auto',
        spaceBetween: 30,
        // loopedSlides: 3,
        centeredSlides: true,
        // centeredSlidesBounds: true,        
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    changeTab(key) {
      if (key === 1) {
        let routeData = this.$router.resolve({
          path: '/Map'
        });
        window.open(routeData.href, '_blank');
      } else {
        this.activeKey = key;
      }
    },
  },
  mounted() {
    getChildList({ mid: 12 }).then((res) => {
      console.log(res);
      this.bookList = res.data;
    });
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 751px) {
  .pageData-title {
    display: flex;
    // justify-content: center;
    background-image: url(../../../assets/img/titleWrap.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    padding-left: 78px;
    margin-bottom: 58px;
    .title {
      height: 84px;
      width: 248px;
      line-height: 84px;
      text-align: center;
      color: #15385b;
      cursor: pointer;
      font-size: 28px;
    }
    .activeTitle {
      background: url(../../../assets/img/newsCheckBoeder.png);
      background-size: cover;
      // width: 248px;
      padding-left: 65px;
      width: 258px;
      padding-right: 10px;
      box-sizing: border-box;
    }
  }
  .pageData-wrap {
    position: relative;
    margin: 0 58px;
    height: 476px;
    display: flex;
    justify-content: center;
    .pageData-content {
      // position: absolute;
      // top: 0;
      // left: 0;
      // z-index: 1;
      // padding-left: 200px;
      a {
        display: inline-block;
        width: 275px;
        height: 380px;
        background: url(../../../assets/img/book.png);
        background-size: cover;
        position: relative;
        margin-left: 40px;
        margin-right: 80px;
        span {
          display: inline-block;
          position: absolute;
          top: 30px;
          right: 72px;
          width: 20px;
          font-size: 24px;
          color: #5b341f;
          line-height: 1.3;
        }
      }
    }
  }

  .pageData-icon {
    width: 183px;
    height: 180px;
    margin: 32px auto 20px;
    background: url(../../../assets/img/pageData-icon.png) no-repeat;
    background-size: cover;
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 750px) {
  .pageData-title {
    display: flex;
    background-image: url(../../../assets/img/app/newsTabBg.png);
    background-size: cover;
    width: 93vw;
    margin: 0 auto 0.56rem;
    .title {
      height: 0.9rem;
      width: 2.2rem;
      line-height: 0.9rem;
      text-align: center;
      color: #15385b;
      cursor: pointer;
      font-size: 0.28rem;
      // background-image: url(../../../assets/img/tabTitleBg.png);
    }
    .activeTitle {
      background: url(../../../assets/img/app/newsCheckBorder.png);
      background-size: cover;
      // width: 248px;
      position: relative;
    }
    .activeTitle::before {
      content: "";
      display: block;
      position: absolute;
      top: 0.05rem;
      left: 0.06rem;
      width: 0.35rem;
      height: 0.4rem;
      background: url(../../../assets/img/app/newsCheckIcon.png) no-repeat;
      background-size: contain;
    }
  }
  .pageData-wrap {
    position: relative;
    // margin: 0 58px;
    // height: 476px;
    .pageData-content {
      width: 80vw;
      margin: 0 auto;
      // border: 1px solid #000;
      a {
        display: block;
        width: 2rem;
        height: 3.1rem;
        // background-color: rgba($color: #000000, $alpha: 0.8);
        background-image: url(../../../assets/img/book.png);
        background-size: cover;
        position: relative;
        span {
          display: inline-block;
          position: absolute;
          top: 0.2rem;
          right: 0.18rem;
          width: 0.4rem;
          font-size: 0.28rem;
          color: #5b341f;
        }
      }
    }
  }

  .pageData-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 1rem auto 0.5rem;
    background: url(../../../assets/img/pageData-icon.png) no-repeat;
    background-size: cover;
  }

  .book::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }
  .swiper-slide-active .book::after {
    display: none;
  }

  .swiper-slide .book {
    transition: 300ms;
    transform: scale(0.75);
  }
  .swiper-slide-active .book {
    transform: scale(1);
  }
}
</style>