<template>
  <div class="introduction-wrap">
    <div class="introduction-content">
      <div
        class="introduction introduction1"
        @click="showThisChild(1)"
        @mousemove="defaultShowAIntroduction = false"
      >
        <img
          v-if="isMobile"
          src="../../../assets/img/app/home/Introduction1.png"
          alt=""
          class="content-img"
        />
        <img
          v-else
          src="../../../assets/img/Introduction1.png"
          alt=""
          class="content-img"
        />

        <div
          class="active-border active-border-default"
          :class="{ mobileBorder: showChild === 1 }"
        ></div>
        <div
          v-show="isMobile ? showChild === 1 : true"
          class="introduction-active introduction-active1"
        >
          <div class="active-title">
            <div
              @click="activeKey = 1"
              :class="{ checkTitle: activeKey === 1 }"
            >
              初心者ガイド
            </div>
            <div
              @click="activeKey = 2"
              :class="{ checkTitle: activeKey === 2 }"
            >
              攻略
            </div>
          </div>
          <div class="active-content">
            <div v-show="activeKey === 1" class="active-content-key">
              <div v-if="contentList[0].length">
                <div v-for="item in contentList[0]" :key="item.id">
                  <a :href="item.jump_url" v-if="item.jump_url" target="_blank">
                    {{ item.title }}
                  </a>
                  <router-link
                    v-else
                    :to="'/IntroductionDetail/' + item.id"
                    target="_blank"
                  >
                    {{ item.title }}
                  </router-link>
                </div>
              </div>
              <div v-else class="noMessage">メッセージがありません</div>

              <router-link
                to="/Introduction"
                class="introductionMore smallMore"
                target="_blank"
                v-if="contentList[0].length"
              >
                <img
                  v-if="isMobile"
                  src="../../../assets/img/news-more.png"
                  alt=""
                />
                <img v-else src="../../../assets/img/news-more.png" alt="" />
              </router-link>
            </div>
            <div v-show="activeKey === 2" class="active-content-key">
              <div v-if="contentList[1].length">
                <div v-for="item in contentList[1]" :key="item.id">
                  <a :href="item.jump_url" v-if="item.jump_url" target="_blank">
                    {{ item.title }}
                  </a>
                  <router-link
                    v-else
                    :to="'/IntroductionDetail/' + item.id"
                    target="_blank"
                  >
                    {{ item.title }}
                  </router-link>
                </div>
              </div>
              <div v-else class="noMessage">メッセージがありません</div>
              <router-link
                to="/Introduction"
                class="introductionMore smallMore"
                target="_blank"
                v-if="contentList[1].length"
              >
                <img
                  v-if="isMobile"
                  src="../../../assets/img/news-more.png"
                  alt=""
                />
                <img v-else src="../../../assets/img/news-more.png" alt="" />
              </router-link>
            </div>
          </div>
        </div>

        <div class="fillers"></div>
      </div>
    </div>

    <div class="introduction-content">
      <div
        class="introduction introduction2"
        @click="showThisChild(2)"
        @mousemove="defaultShowAIntroduction = false"
      >
        <img
          v-if="isMobile"
          src="../../../assets/img/app/home/Introduction2.png"
          alt=""
          class="content-img"
        />
        <img
          v-else
          src="../../../assets/img/Introduction2.png"
          alt=""
          class="content-img"
        />
        <div
          class="active-border active-border-default"
          :class="{ mobileBorder: showChild === 2 }"
        ></div>
        <!-- <div class="content-img content-img2"></div> -->
        <div
          v-show="isMobile ? showChild === 2 : true"
          class="introduction-active introductionActive2"
        >
          <div class="active-content">
            <div class="mP2" v-if="contentList[2].length">
              <div v-for="item in contentList[2]" :key="item.id">
                <a :href="item.jump_url" v-if="item.jump_url" target="_blank">
                  {{ item.title }}
                </a>
                <router-link v-else :to="'/IntroductionDetail/' + item.id" target="_blank">
                  {{ item.title }}
                </router-link>
              </div>
            </div>
            <div v-else class="noMessage">メッセージがありません</div>
          </div>
          <router-link v-if="contentList[2].length" to="/Introduction" class="introductionMore" target="_blank">
            <img
              v-if="isMobile"
              src="../../../assets/img/news-more.png"
              alt=""
            />
            <img v-else src="../../../assets/img/news-more.png" alt="" />
          </router-link>
        </div>
        <div class="fillers"></div>
      </div>
    </div>

    <div class="introduction-content">
      <div
        class="introduction introduction3"
        @click="showThisChild(3)"
        @mousemove="defaultShowAIntroduction = false"
      >
        <img
          v-if="isMobile"
          src="../../../assets/img/app/home/Introduction3.png"
          alt=""
          class="content-img"
        />
        <img
          v-else
          src="../../../assets/img/Introduction3.png"
          alt=""
          class="content-img"
        />
        <div
          class="active-border active-border-default"
          :class="{ mobileBorder: showChild === 3 }"
        ></div>
        <div
          v-show="isMobile ? showChild === 3 : true"
          class="introduction-active introductionActive3"
        >
          <div class="active-content">
            <div :class="{ mP2: isMobile }" v-if="contentList[3].length">
              <div v-for="item in contentList[3]" :key="item.id">
                <a :href="item.jump_url" v-if="item.jump_url" target="_blank">
                  {{ item.title }}
                </a>
                <router-link v-else :to="'/IntroductionDetail/' + item.id" target="_blank">
                  {{ item.title }}
                </router-link>
              </div>
            </div>
            <div v-else class="noMessage">メッセージがありません</div>
          </div>
          <router-link v-if="contentList[3].length" to="/Introduction" class="introductionMore" target="_blank">
            <img
              v-if="isMobile"
              src="../../../assets/img/news-more.png"
              alt=""
            />
            <img v-else src="../../../assets/img/news-more.png" alt="" />
          </router-link>
        </div>
        <div class="fillers"></div>
      </div>
    </div>

    <div class="introduction-content">
      <div
        class="introduction introduction4"
        @click="showThisChild(4)"
        @mousemove="defaultShowAIntroduction = false"
      >
        <img
          v-if="isMobile"
          src="../../../assets/img/app/home/Introduction4.png"
          alt=""
          class="content-img"
        />
        <img
          v-else
          src="../../../assets/img/Introduction4.png"
          alt=""
          class="content-img"
        />
        <div
          class="active-border active-border-default"
          :class="{ mobileBorder: showChild === 4 }"
        ></div>
        <div
          v-show="isMobile ? showChild === 4 : true"
          class="introduction-active introductionActive4"
        >
          <div class="active-content">
            <div :class="{ mP2: isMobile }" v-if="contentList[4].length">
              <div v-for="item in contentList[4]" :key="item.id">
                <a :href="item.jump_url" v-if="item.jump_url" target="_blank">
                  {{ item.title }}
                </a>
                <router-link v-else :to="'/IntroductionDetail/' + item.id" target="_blank">
                  {{ item.title }}
                </router-link>
              </div>
            </div>
            <div v-else class="noMessage">メッセージがありません</div>
          </div>
          <router-link
            v-if="contentList[4].length"
            to="/Introduction"
            class="introductionMore"
            target="_blank"
          >
            <img
              v-if="isMobile"
              src="../../../assets/img/news-more.png"
              alt=""
            />
            <img v-else src="../../../assets/img/news-more.png" alt="" />
          </router-link>
        </div>
        <div class="fillers"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { getNewest,getArticleList } from "@/api/index";
import { getChildList,getArticleList } from "@/api/index";

export default {
  name: "Introduction",
  data() {
    return {
      activeKey: 1,
      showChild: 1,
      contentList: [],
      defaultShowAIntroduction: true,
    };
  },
  computed: mapState(["isMobile"]),
  methods: {
    changeIntroduction(key) {
      this.activeKey = key;
    },
    showThisChild(key) {
      // console.log(key);
      this.showChild = key;
    },
  },
  mounted() {
    if (this.isMobile) {
      this.defaultShowAIntroduction = false;
    }
      const device_type = this.isMobile?'mob':'web';
      getChildList({mid: 4});
      Promise.all([
        getArticleList({ mid: 19, num: 3,device_type }),
        getArticleList({ mid: 20, num: 3,device_type }),
        getArticleList({ mid: 23, num: 5,device_type }),
        getArticleList({ mid: 24, num: 5,device_type }),
        getArticleList({ mid: 25, num: 5,device_type }),
      ])
        .then((result) => {
          result.map((item) => {
            this.contentList.push(item.data.list);
          });
        })
        .catch((error) => {
          console.log(error);
        });
  },
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss" scoped>
@media (max-width: 750px) {
  .introduction-wrap {
    display: flex;
    justify-content: space-between;
    margin: 0.2rem 0.3rem 3.24rem;
    position: relative;
    .introduction {
      width: 22vw;
    }
  }

  .content-img {
    width: 100%;
    // height: 1rem;
    // height: 100%;
  }
  .active-border-default {
    border-top: 0.1rem solid #84bfd1;
  }
  .mobileBorder {
    border-top: 0.1rem solid #4696af;
  }
  .mobileBorder::after {
    content: "";
    display: block;
    // border-top: 0.1rem solid transparent;
    border-top: 0.1rem solid #4696af;
    border-left: 0.1rem solid transparent;
    border-right: 0.1rem solid transparent;
    height: 0;
    width: 0;
    margin: 0 auto;
  }

  .active-content {
    // width: 4rem;
    // padding-bottom: 0.7rem;
    // padding: 5px 98px 46px 36px ;
    position: relative;
    height: 2rem;
    // padding-top: .2rem;
    a {
      display: block;
      color: #15385b;
      font-size: 0.22rem;
      line-height: 0.26rem;
      padding: 0.1rem 0;
      text-indent: 0.2rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
    a:hover {
      color: #b79350;
    }
  }
  .active-content-key {
    padding-top: 0.2rem;
  }
  .introductionMore {
    // position: absolute;
    // right: 0.1rem;
    // bottom: 0.1rem;
    // height: 0.4rem;
    display: block;
    margin: 0.1rem;
    float: right;
    img {
      display: block;
      height: 0.4rem;
    }
  }

  .active-title {
    display: flex;
    div {
      width: 50%;
      text-align: center;
      background: #c8e6ed;
      font-size: 0.28rem;
      line-height: 0.7rem;
    }
    .checkTitle {
      background: #add8e2;
    }
  }

  .introduction-active {
    // display: none;
    position: absolute;
    // bottom: -3.2rem;
    left: 0;
    width: 93vw;
    background-image: url(../../../assets/img/introduction-active.png);
    background-size: cover;
  }

  .introductionActive2,
  .introductionActive3,
  .introductionActive4 {
    // bottom: -2.8em;
    a {
      line-height: 0.22rem;
    }
  }
  .noMessage {
    text-align: center;
    font-size: 0.3rem;
    // line-height: 30px;
    padding-top: 0.33rem;
  }
  .mP2 {
    padding-top: 0.2rem;
  }
}

@media (min-width: 751px) {
  .introduction-wrap {
    display: flex;
    justify-content: space-between;
    margin: 0 58px;
    margin-top: 123px;
    min-height: 260px;
    .introduction-content {
      width: 21%;
    }
    .introduction {
      position: relative;
      // height: 166px;
      // padding-bottom: 20px;
    }
    .active-border {
      border-top: 17px solid #84bfd1;
    }
    .active-border::after {
      content: "";
      display: block;
      border-top: 15px solid transparent;
      // border-top: 15px solid #4696af;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      height: 0;
      width: 0;
      margin: 0 auto;
    }
    .active-border-hover {
      // position: absolute;
      // bottom: 0;
      width: 100%;
      border-top: 17px solid #4696af;
    }

    .active-border-hover::after {
      border-top: 15px solid #4696af;
    }

    .introduction1::before,
    .introduction3::before {
      content: "";
      display: block;
      position: absolute;
      height: 243px;
      width: 143px;
      right: -50px;
      top: -77px;
      background: url(../../../assets/img/iC11.png) no-repeat center;
      background-size: contain;
      z-index: 9;
    }
    .introduction2::before,
    .introduction4::before {
      content: "";
      display: block;
      position: absolute;
      height: 218px;
      width: 202px;
      right: -50px;
      top: -80px;
      background: url(../../../assets/img/iC22.png) no-repeat center;
      background-size: contain;
      z-index: 9;
    }
  }

  .content-img {
    width: 100%;
  }

  .introduction-active {
    // display: none;
    position: absolute;
    background-image: url(../../../assets/img/introduction-active.png);
    background-size: 100% 100%;
    z-index: 2;
    height: 0;
    overflow: hidden;
    transition-duration: 0.5s;
  }

  .introduction-active-hover {
    display: block;
  }

  .active-content {
    width: 407px;
    font-size: 18px;
    line-height: 28px;
    padding: 5px 28px 46px 36px;
    position: relative;
    a {
      display: block;
      color: #15385b;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      line-height: 1.8;
    }
    a:hover {
      color: #b79350;
    }
  }
  .active-title {
    display: flex;
    cursor: pointer;
    div {
      width: 50%;
      text-align: center;
      background: #c8e6ed;
      font-size: 24px;
      line-height: 51px;
    }
    .checkTitle {
      background: #add8e2;
    }
  }
  .introductionMore {
    // position: absolute;
    // right: 37px;
    // bottom: 9px;
    display: block;
    margin: 10px;
    float: right;
    width: 100px;
    img {
      width: 100%;
    }
  }
  .smallMore {
    width: 100px;
    margin: 8px 8px;
  }
  .introduction1 .active-content a {
    line-height: 1.8;
  }

  .introductionActive2 {
    // display: block;
    // bottom: -188px;
    left: 0;
    .introduction-active {
      background-position: bottom;
    }
  }
  .introductionActive3 {
    // bottom: -188px;
    left: 0;
    .introduction-active {
      background-position: bottom;
    }
  }
  .introductionActive4 {
    // bottom: -188px;
    right: 0;
    .introduction-active {
      background-position: bottom;
    }
  }

  .introductionActive4 .active-content,
  .introductionActive2 .active-content,
  .introductionActive3 .active-content {
    height: 80px;
    position: relative;
  }
  .introduction:hover .introduction-active {
    height: 187px;
  }
  .introduction:hover .introduction-active1 {
    height: 200px;
  }
  .introduction:hover .active-border {
    border-top: 17px solid #4696af;
  }
  .introduction:hover .active-border::after {
    border-top: 15px solid #4696af;
  }
  .fillers {
    height: 0;
    transition-duration: 0.5s;
  }
  .introduction:hover .fillers {
    height: 160px;
  }

  .noMessage {
    text-align: center;
    font-size: 20px;
    // line-height: 30px;
    padding-top: 30px;
  }

  .mP2 {
    padding-top: 6px;
  }
}
</style>

