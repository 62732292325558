<template>
  <div v-if="isMobile" class="features-container">
    <div class="features-wrap">
      <div class="gamePlay">
        <img src="../../../assets/img/gamePlay.png" alt="" />
      </div>

      <AppDirectory v-if="detailKey === 0" />
      <AppPartner v-if="detailKey === 1" />
      <AppSoul v-if="detailKey === 2" />

      <div class="features">
        <div
          class="features-item"
          :class="{ featuresStyle: detailKey === 0 }"
          @click="goDetail(0)"
        >
          <span> 職業 </span>
        </div>
        <div
          class="features-item"
          :class="{ featuresStyle: detailKey === 1 }"
          @click="goDetail(1)"
        >
          <span> 従魔 </span>
        </div>
        <div
          class="features-item"
          :class="{ featuresStyle: detailKey === 2 }"
          @click="goDetail(2)"
        >
          <span> 元魂 </span>
        </div>
        <img src="../../../assets/img/shui.png" alt="" />
      </div>

      <div class="gamePlay portraitIcon">
        <img src="../../../assets/img/portraitIcon.png" alt="" />
      </div>
    </div>
  </div>

  <div v-else class="features-container">
    <div class="features-wrap">
      <div class="gamePlay">
        <img src="../../../assets/img/gamePlay.png" alt="" />
      </div>

      <div
        class="content"
        :class="{ content1: detailKey == 1, content2: detailKey == 2 }"
      >
        <div
          class="components-wrap"
          :class="{ moveUp1: detailKey == 1, moveUp2: detailKey == 2 }"
        >
          <Occupation v-if="detailKey === 0" />
          <Partner v-if="detailKey === 1" />
          <Soul v-if="detailKey === 2" />
        </div>
      </div>
      <div class="features">
        <div
          class="features-item"
          :class="{ featuresStyle: detailKey === 0 }"
          @click="goDetail(0)"
        >
          <span> 職業 </span>
        </div>
        <div
          class="features-item"
          :class="{ featuresStyle: detailKey === 1 }"
          @click="goDetail(1)"
        >
          <span> 従魔 </span>
        </div>
        <div
          class="features-item"
          :class="{ featuresStyle: detailKey === 2 }"
          @click="goDetail(2)"
        >
          <span> 元魂 </span>
        </div>
        <img src="../../../assets/img/shui.png" alt="" />
      </div>
      <div class="gamePlay portraitIcon">
        <img src="../../../assets/img/portraitIcon.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/util";
import AppDirectory from "../../feature/components/AppDirectory.vue";
import AppPartner from "../../feature/components/AppPartner.vue";
import AppSoul from "../../feature/components/AppSoul";

import Occupation from "../../feature/components/Occupation.vue";
import Partner from "../../feature/components/Partner.vue";
import Soul from "../../feature/components/Soul.vue";

export default {
  name: "Features",
  components: { AppDirectory, AppPartner, AppSoul, Occupation, Partner, Soul },
  data() {
    return {
      isMobile,
      featuresList: ["職業", "従魔", "元魂"],
      featuresIndex: 0,
      stageList: ["融仙期", "変化期", "凝魄期", "定神期", "開霊期", "通窍期"],
      stageActiveKey: 0,
      scrollHeight: 0,
      scrollHeightList: [],
      soulStageActive: 0,
      activeBallKey: 0,
      detailKey: 0
    };
  },
  methods: {
    goDetail(position) {
      this.detailKey = position;
      // this.$router.push("/Features");
      // this.$router.push(`/Features?position=${position}`);
    },
    setScrollHeight() {
      if (this.scrollHeight === 0) {
        const scrollHeight =
          document.body.scrollHeight - document.body.offsetHeight;
        console.log(scrollHeight);
        this.scrollHeight = scrollHeight;
        this.scrollHeightList = [
          (this.scrollHeight * 0.4).toFixed(0),
          (this.scrollHeight * 0.79).toFixed(0),
        ];
      }
    },
    scrollTo(state) {
      console.log(1);
      this.setScrollHeight();
      this.featuresIndex = state;
      switch (state) {
        case 0:
          window.scrollTo(0, 300);
          break;
        case 1:
          console.log(this.scrollHeightList);
          window.scrollTo(0, Number(this.scrollHeightList[0]) + 2);
          break;
        case 2:
          window.scrollTo(0, Number(this.scrollHeightList[1]) + 2);
          break;
        default:
          console.log(state);
          break;
      }
    },
    handleScroll() {
      this.setScrollHeight();
      const scroll =
        document.documentElement.scrollTop || document.body.scrollTop;

      // console.log(scroll, this.scrollHeight);
      if (scroll <= this.scrollHeightList[0]) {
        this.featuresIndex = 0;
      } else if (
        scroll > this.scrollHeightList[0] &&
        scroll <= this.scrollHeightList[1]
      ) {
        this.featuresIndex = 1;
      } else if (scroll > this.scrollHeightList[1]) {
        this.featuresIndex = 2;
      }
    },
    changeActiveStage(key) {
      console.log(key);
      this.soulStageActive = key;
    },
    checkPartent(event) {
      console.log(event);
    },
    changeOccupation(key) {
      this.activeBallKey = key;
    },
    nextOccupation(value) {
      const { activeBallKey } = this;
      console.log(value, activeBallKey);
      if (value) {
        if (activeBallKey !== 3) {
          this.activeBallKey += 1;
        } else {
          this.activeBallKey = 0;
        }
      } else {
        if (activeBallKey !== 0) {
          this.activeBallKey -= 1;
        } else {
          this.activeBallKey = 3;
        }
      }
    },
  },
  mounted() {},
};
</script>


<style lang="scss" scoped>
@media (max-width: 750px) {
  .features-container {
    // margin: 242px 183px 70px 85px;
    .features-wrap {
      position: relative;
      padding: 0.1rem 0 0.6rem;
    }
  }
  .features-content {
    position: relative;
  }
  .ellipse {
    position: absolute;
    top: 3.9rem;
    left: 1rem;
    width: 5.5rem;
    height: 2rem;
    // border: 1px solid #1c3f61;
    border-radius: 50%;
    z-index: 0;
    border-top: none;
    .yunTop {
      position: absolute;
      top: 236px;
      left: 0;
      width: 84%;
    }
    .yunBottom {
      position: absolute;
      bottom: -80px;
      left: 0;
      width: 35%;
    }
  }
  .features-character {
    width: 80%;
    height: auto;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    // position: relative;
  }
  .gamePlay {
    // position: absolute;
    // top: 0.3rem;
    // left: 45%;
    width: 1.5rem;
    height: 1.5rem;
    margin: 1rem auto 0;
    img {
      width: 100%;
    }
  }

  .features {
    position: absolute;
    top: 30%;
    right: 0.1rem;
    // height: 2.5rem;
    width: 0.6rem;
    z-index: 10;
    // padding-top: .4rem;
    .features-item {
      font-size: 0.24rem;
      color: #fff;
      text-align: center;
      height: 1.6rem;
      box-sizing: border-box;
      background-size: cover;
      background-image: url(../../../assets/img/features.png);
      background-size: cover;
      span {
        display: inline-block;
        text-align: center;
        width: 0.3rem;
        line-height: 0.33rem;
        padding-top: 0.5rem;
      }
    }
    .featuresStyle {
      background-image: url(../../../assets/img/featuresCheck.png);
    }
    img {
      margin-left: 0.09rem;
      width: 0.3rem;
    }
  }
  .features::before {
    content: "";
    display: block;
    height: 0.4rem;
    background-image: url(../../../assets/img/rope.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 0.09rem 0.9rem;
  }
  .portraitIcon {
    // bottom: 0.2rem;
    // top: auto;
  }
}
</style>

<style lang="scss" scoped>
@media (min-width: 751px) {
  .features-container {
    // margin: 242px 183px 70px 85px;

    .features-wrap {
      position: relative;
      padding-bottom: 30px;
      padding-top: 100px;
    }
  }
  .features-content {
    position: relative;
  }

  .content {
    position: relative;
    margin: 40px auto 120px;
  }
  .content1 {
    // margin: 0 auto;
    margin: 0 auto 80px;
  }
  .content2 {
    margin: 0 auto;
  }
  .moveUp1 {
    position: relative;
    top: -33px;
  }
  .moveUp2 {
    margin-top: -200px;
    margin-bottom: 200px;
  }

  .gamePlay {
    // position: absolute;
    // top: 0;
    // left: 45%;
    width: 182px;
    height: 182px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }

  .features {
    position: absolute;
    top: 300px;
    right: 80px;
    height: 586px;
    width: 60px;
    background-image: url(../../../assets/img/rope.png);
    padding-top: 80px;
    background-repeat: no-repeat;
    background-position: top center;
    z-index: 5;
    .features-item {
      font-size: 26px;
      color: #fff;
      text-align: center;
      height: 167px;
      box-sizing: border-box;
      background-size: cover;
      background-image: url(../../../assets/img/features.png);
      background-size: cover;
      cursor: pointer;
      span {
        display: inline-block;
        text-align: center;
        width: 26px;
        line-height: 33px;
        padding-top: 50px;
      }
    }
    .featuresStyle {
      background-image: url(../../../assets/img/featuresCheck.png);
    }
    .features-style {
      background-image: url(../../../assets/img/featuresCheck.png);
    }
    img {
      margin-left: 8px;
      width: 32px;
    }
  }

  .portraitIcon {
    bottom: 0;
    top: auto;
  }
}
</style>
